export const allEntrepreneurs = [
   {
       "id": "ep1",
       "name": "Sarah Grover",
       "company": "Utah Furniture Deals",
       "image": "/images/EntrepreneurImages/Ep-1-Taking-Advantage-of-Momentum-with-Sarah-Grover.jpg",
       "title": "Episode 01 - Taking Advantage of Momentum",
       "read": "https://www.getweave.com/ep-01-taking-advantage-of-momentum-sarah-grover-and-utah-furniture-deals/",
       "watch" : "",
       "listen" : "https://www.buzzsprout.com/1090415/3759005-episode-1-taking-advantage-of-momentum-with-sarah-grover-and-utah-furniture-deals?client_source=small_player&iframe=true&referrer=https://www.buzzsprout.com/1090415/3759005-episode-1-taking-advantage-of-momentum-sarah-grover-and-utah-furniture-deals.js?container_id=buzzsprout-player-3759005&player=small",
       "description": "As a mom and nurse, owning a retail business was not an imagined future. But Sarah loved interior design and decorating, and she loved a good deal. Throw in her competitive spirit, and it’s no wonder she now has a retail business with design services. What is a surprise is how fast it all happened. You’ll hear her talk about the simple things she did in the beginning, how she quickly identified her target audience, and how she’s taken advantage of that momentum.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000475224491",
       "spotify" : "https://open.spotify.com/episode/1gRq66R4K4NLzq1EfoNkMz?si=VFLEkYHKSNq3m4yY0MdWAw",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zNzU5MDA1",
       "casts" : "https://overcast.fm/+be5cAmpUU"
   },
   {
       "id": "ep2",
       "name": "Jessica Atkinson",
       "company": "Hygiene Edge",
       "image": "/images/EntrepreneurImages/Ep-2-Fillig-the-Gap-with-Jessica-Atkinson.jpg",
       "title": "Episode 02 - Filling the Gap",
       "read": "https://www.getweave.com/ep-02-filling-the-gap-jessica-atkinson-and-hygiene-edge/",
       "watch" : "",
       "listen" : "https://www.buzzsprout.com/1090415/3811628-episode-2-filling-the-gap-with-jessica-atkinson-hygiene-edge?client_source=small_player&iframe=true&referrer=https://www.buzzsprout.com/1090415/3811628-episode-2-filling-the-gap-jessica-atkinson-and-hygiene-edge.js?container_id=buzzsprout-player-3811628&player=small",
       "description": "As a dental hygienist, Jessica has involved herself in every aspect of her line of work from clinical hours to education to lobbying. It would be an understatement to say that she’s passionate about what she does. That passion fuels her to educate in the traditional sense-- teaching hygiene students-- but also to educate her peers and dentists on how hygienists can practice to the full scope of their abilities to better serve patients and allow dentists more focused time for their expertise. Listen to how she talks about the importance of including others in your cause, those that help you with your ideas, but also those that play a crucial role in pushing that idea forward.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000475374582",
       "spotify" : "https://open.spotify.com/episode/2mtmHuT8OGTqotbXF9kUHs?si=6Utsst3ySNKGhFryCfOvXQ",
       "google" : "",
       "casts" : "https://overcast.fm/+be5fFq8ok",
   },
   {
       "id": "ep3",
       "name": "Steve Arntz",
       "company": "Campfire",
       "image": "/images/EntrepreneurImages/Ep-3-Taking-Calculated-Risks-with-Steve-Arntz.jpg",
       "title": "Episode 03 - Solving New Problems & Taking Calculated Risks",
       "read": "https://www.getweave.com/ep-03-solving-new-problems-taking-calculated-risks-steve-arntz-bookclub/",
       "watch" : "",
       "listen" : "https://www.buzzsprout.com/1090415/3811655-episode-3-solving-new-problems-taking-calculated-risks-with-steve-arntz-campfire?client_source=small_player&iframe=true&referrer=https://www.buzzsprout.com/1090415/3811655-episode-3-solving-new-problems-taking-calculated-risks-steve-arntz-campfire.js?container_id=buzzsprout-player-3811655&player=small",
       "description": "Today I’m talking with Steve Arntz, co-founder of a new startup called Campfire. However, as you listen to the episode, you’ll hear him refer to BookClub. Steve and his cofounder, Todd Ericksen, move fast. Their company started as BookClub, but they saw within a few months how that could limit future offerings. So, not long after this interview, they officially launched their rebrand. It’s fascinating to hear how he helped launch this SaaS startup. And you’ll love his 3 takeaways at the end. You can contact Steve and Todd on Linkedin. And be sure to check out their new website, getcampfire.com where they’ll focus product development on strengthening human connection. BookClub is where they’ve started, but you’ll want to follow them. ",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000475374583",
       "spotify" : "https://open.spotify.com/episode/2rmKsGBzKk8uUtHuknJEL3?si=z5IB3MpYRu-PlTcK19Lqeg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zODExNjU1",
       "casts" : "https://overcast.fm/+be5e-fvTk",
   },
   {
       "id": "ep4",
       "name": "Jesse Harding",
       "company": "Weave Product Manager",
       "image": "/images/EntrepreneurImages/Ep-4-Calling-Yourself-Creative-with-Jesse-Harding.jpg",
       "title": "Episode 04 - Calling Yourself Creative",
       "read": "https://www.getweave.com/ep-04-calling-yourself-creative-jesse-harding/",
       "watch" : "",
       "listen" : "https://www.buzzsprout.com/1090415/3811679-episode-4-calling-yourself-creative-with-jesse-harding?client_source=small_player&iframe=true&referrer=https://www.buzzsprout.com/1090415/3811679-episode-4-calling-yourself-creative-jesse-harding.js?container_id=buzzsprout-player-3811679&player=small",
       "description": "Today I’m talking with Jesse Harding. Jesse is a serial entrepreneur. He’s worked on several software startups, as the idea man, the advisor, the coder, and so much more. He’s worn all of the hats. There have been projects that have failed, projects that have succeeded, and projects that have taken longer than anticipated. Through it all, Jesse sees each venture as a way to express his creativity. He experiments. He tweaks. He chases his hair brained ideas into card game creation. Because there’s no lack of ideas, he’s not afraid to pick up an idea and pivot if necessary. There’s more where that came from. Listen to how he fosters a space for creativity and a mentality of abundance.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000476041394",
       "spotify" : "https://open.spotify.com/episode/779b4K4sFcI5Awjd3nrggM?si=rUKIcWfSRhyhBVJ1qeYLEg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zODExNjc5",
       "casts" : "https://overcast.fm/+be5eR0BUk",
   },
   {
       "id": "ep5",
       "name": "Beth Hardy & Chelsea Udell",
       "company": "Heart Tones Birth",
       "image": "/images/EntrepreneurImages/Ep-5-Avoiding-Burnout-with-Beth-Hardy-&-Chelsea-Udell.jpg",
       "title": "Episode 5 - Avoiding Burnout with Beth Hardy & Chelsea Udell",
       "read": "https://www.getweave.com/ep-05-avoiding-burnout-beth-hardy-chelsea-udell-with-heart-tones-birth/",
       "watch" : "https://www.youtube.com/embed/vl5_WHMdVDQ?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/3811706-episode-5-avoiding-burnout-with-beth-hardy-chelsea-udell-from-heart-tones-birth.js?container_id=buzzsprout-player-3811706&player=small",
       "description": "Beth started the company for doula services back in 2015. Not unlike entrepreneurs in other industries, doulas face a high burnout rate because many of them go solo and stay solo. Most don’t last more than 3 years. Beth’s passion for the field pushed her to realize she needed a partner so she could continue to do her work. Looking for a partner who shares your same vision, ideals, and focus is daunting, and vulnerable. You wonder if someone is really going to want to step into the ring with you. But she found Chelsea and they’ve been a great tag team. Listen to how a partnership helped Beth better understand her business and services.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000476738663",
       "spotify" : "https://open.spotify.com/episode/73ud41fGN81zdbnRfZO3Wg?si=Wj0ZF2qrTqCjwoIN7iP8-w",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zODExNzA2",
       "casts" : "https://overcast.fm/+be5f5JRYA",
   },
   {
       "id": "ep6",
       "name": "Vanessa Perkins",
       "company": "Everyday Glamourhood",
       "image": "/images/EntrepreneurImages/Ep-6-Conquering-Imposter-Syndrome-with-Vanessa-Perkins.jpg",
       "title": "Episode 6 - Conquering Imposter Syndrome with Vanessa Perkins",
       "read": "https://www.getweave.com/ep-06-conquering-imposter-syndrome-with-vanessa-perkins/",
       "watch" : "https://www.youtube.com/embed/lEz_58zPUXw?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4092923-episode-6-conquering-imposter-syndrome-with-vanessa-perkins.js?container_id=buzzsprout-player-4092923&player=small",
       "description": "Vanessa is a professional makeup artist and has worked in fashion, advertising, fitness, bridal and commercial. She has been the key makeup artist for many photoshoots and commercial productions, and has worked at the Sundance Film Festival the last few years. Her specialties include full glamour, editorial, and natural looks. Vanessa transitioned from corporate work to full time mom to self employed. Listen to how she talks about conquering impostor syndrome in all of its different forms.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000477508155",
       "spotify" : "https://open.spotify.com/episode/6oVI3FpBrToFjeayoMgyw3?si=snWj6EN0SiOnaeSCFWUqtg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00MDkyOTIz",
       "casts" : "https://overcast.fm/+be5e6NI0c",
   },
   {
       "id": "ep7",
       "name": "Brandon Rodman",
       "company": "Weave",
       "image": "/images/EntrepreneurImages/Ep-7-Starting-a-Business-In-a-Recession-with-Brandon-Rodman.jpg",
       "title": "Episode 7 - Starting a Business In a Recession with Brandon Rodman",
       "read": "https://www.getweave.com/episode-7-starting-a-business-in-a-recession-with-brandon-rodman/",
       "watch" : "https://www.youtube.com/embed/PgJkLCPhIeo?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/3928187-episode-7-starting-a-business-in-a-recession-with-brandon-rodman.js?container_id=buzzsprout-player-3928187&player=small",
       "description": "It was during the last recession that he began working on a company that would eventually lead to what Weave is today. In fact, a lot of companies were started during that recession. But how is it done? Adaptability, scrappiness, and passion are Brandon’s key things. Listen to how his background in door to door sales helped him develop those attributes that then helped him launch a business at the beginning of a recession.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000478300250",
       "spotify" : "https://open.spotify.com/episode/7cz1GZCe8HXx9Awaz8eV42?si=41t1Tp0ASnCOtErao6LdgQ",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zOTI4MTg3",
       "casts" : "https://overcast.fm/+be5e76M40",
   },
   {
       "id": "ep8",
       "name": "Curt Roberts",
       "company": "Kickstart VC",
       "image": "/images/EntrepreneurImages/Ep.-8-Orders-of-Magnitude-Better-with-Curt-Roberts.jpg",
       "title": "Episode 8 - Orders of Magnitude Better with Curt Roberts",
       "read": "https://www.getweave.com/ep-08-orders-of-magnitude-better-with-vc-curt-roberts/",
       "watch" : "https://www.youtube.com/embed/AaW00hL_uQA?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/3928223-episode-8-orders-of-magnitude-better-with-vc-curt-roberts.js?container_id=buzzsprout-player-3928223&player=small",
       "description": "Curt sees hundreds of ideas every year. Most of them aren’t new. So why would an idea get funded? Because it’s orders of magnitude better than what currently exists and there’s a path forward. Listen to how he talks about evaluating a business, what makes for a solid business strategy, and how you can work toward getting funded. Keep in mind, very few people do get funded by VC firms. Everything we discuss is still directly applicable to a self funded or bank funded business.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000479390674",
       "spotify" : "https://open.spotify.com/episode/4tWTnmKCjphu50zDVbjdXD?si=PdGF1NVHRF2ghqnS-EdEcg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC0zOTI4MjIz",
       "casts" : "https://overcast.fm/+be5dCfXaU",
   },
   {
       "id": "ep9",
       "name": "Jeff Lyman",
       "company": "Weave",
       "image": "/images/EntrepreneurImages/Ep-9-Selling-With-Emotion-with-Jeff-Lyman.jpg",
       "title": "Episode 9 - Selling With Emotion with Jeff Lyman",
       "read": "https://www.getweave.com/episode-9-selling-with-emotion-with-jeff-lyman/",
       "watch" : "https://www.youtube.com/embed/8bptu9hFYS4?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4107668-episode-9-selling-with-emotion-with-jeff-lyman.js?container_id=buzzsprout-player-4107668&player=small",
       "description": "Prior to Weave, Jeff served as Chief Product Officer at Vivint Smart Home where he was responsible for building multi-million dollar product lines across the firm's security, video, and automation verticals. Before that, Jeff held various leadership roles at Nike. Listen to how a product guy shares lessons on how to sell. Because selling is so crucial for every business, no matter who you are, you’ll have to learn sooner or later. And see how his product knowledge shapes his perception of the world today.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000480932173",
       "spotify" : "https://open.spotify.com/episode/2gLSbOmvMa80eIvky5SUqG?si=mE98Cxa8QByCN0J3BHjnlw",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00MTA3NjY4",
       "casts" : "https://overcast.fm/+be5d2P3cE",
   },
   {
       "id": "ep10",
       "name": "Kendall Coomans",
       "company": "McCarty Salon",
       "image": "/images/EntrepreneurImages/Ep-10-Building-Lasting-Relationships-with-Kendall-Coomans.jpg",
       "title": "Episode 10 - Building Lasting Relationships with Kendall Coomans",
       "read": "https://www.getweave.com/episode-10-building-lasting-relationships-with-kendall-coomans/",
       "watch" : "https://www.youtube.com/embed/EXPiFW6V2x4?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4490090-episode-10-building-lasting-relationships-with-kendall-coomans.js?container_id=buzzsprout-player-4490090&player=small",
       "description": " When you start a business, you start from scratch. And whether you have a proclivity for sales or charisma doesn’t matter-- you need to develop it. Building your clientele is no easy task. Nor does it happen overnight. Kendall has been a stylist for 18 years and in this episode she talks about what it took to gain, build, and retain that clientele, especially amidst a pandemic.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000483578353",
       "spotify" : "https://open.spotify.com/episode/3zAVkL0BJ2HoAcwt0hFcKf?si=5FdtZvVjQkePKWQE9qZNZg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00NDkwMDkw",
       "casts" : "https://overcast.fm/+be5dstSJY",
   },
   {
       "id": "ep11",
       "name": "Sean Foster",
       "company": "Plunj",
       "image": "/images/EntrepreneurImages/Ep-11-Getting-Off-The-Ground-with-Sean-Foster.jpg",
       "title": "Episode 11 - Getting Off The Ground with Sean Foster",
       "read": "https://www.getweave.com/ep-11-getting-off-the-ground-with-sean-foster/",
       "watch" : "https://www.youtube.com/embed/VuwaigATbME?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4504106-episode-11-getting-off-the-ground-with-sean-foster.js?container_id=buzzsprout-player-4504106&player=small",
       "description": "Over the past few years, Sean has done a lot of personal research into cold/hot therapy- otherwise known as contrast therapy. He’d go to a nearby river and take a cold plunge after running. After a while, he noticed some pretty awesome side effects. His anxiety was calming down. And when his wife started joining him for cold showers, her insomnia dissipated. These were two, huge, life altering changes for them and their family. It’s amazing how your performance, attitude, and life overall changes when you’re less anxious and you’re well rested. So, you can imagine how this lit a flame under Sean. He then started making plans to create Plunj. With the way COVID-19 has affected the economy and work places, his path has been accelerated a bit. Listen to how he’s laying the groundwork and how he’s keeping his head high amidst this pandemic.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000485140190",
       "spotify" : "https://open.spotify.com/episode/5jhLpkOzWjif3IIc741xKe?si=lprdRj5CTlqrSCAfgOOEcg",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00NTA0MTA2",
       "casts" : "https://overcast.fm/+be5cki7S4",
   },
   {
       "id": "ep12",
       "name": "Will Humphreys",
       "company": "The Profitable PT",
       "image": "/images/EntrepreneurImages/Ep-12-Profitablity-Unlocks-Possibility-with-Will-Humphreys.jpg",
       "title": "Episode 12 - Profitability Unlocks Possibility with Will Humphreys",
       "read": "https://www.getweave.com/ep-12-profitability-unlocks-possibility-with-will-humphreys/",
       "watch" : "https://www.youtube.com/embed/Ic1XCYoR6GE?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4656797-episode-12-profitability-unlocks-possibility-with-will-humphreys.js?container_id=buzzsprout-player-4656797&player=small",
       "description": "Will is a successful physical therapist in Arizona. He’s owned multiple practices, runs a YouTube channel, and is in the final stages of publishing a book. But he didn’t get there without some serious work and navigation through some bumpy roads. As a result, he also chooses to spend much of his time coaching entrepreneurs. Listen to his experience and why he’s now so passionate about everyone needing a coach and business networking group.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000485748489",
       "spotify" : "https://open.spotify.com/episode/0PQBPsShrjZq1lghCXBKLx?si=xa0_3Re_Q3KDqfs2U6l9dQ",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00NjU2Nzk3",
       "casts" : "https://overcast.fm/+be5fa3WF8",
   },
   {
       "id": "ep13",
       "name": "Quinell Dixon",
       "company": "Embrogo Brand",
       "image": "/images/EntrepreneurImages/Ep-13-Bringing-Your-Full-Self-to-the-Job-with-Quinell-Dixon.jpg",
       "title": "Episode 13 - Bringing Your Full Self to the Job with Quinell Dixon",
       "read": "https://www.getweave.com/ep-13-bringing-your-full-self-to-the-job/",
       "watch" : "https://www.youtube.com/embed/NFP055qlHeo?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4750970-episode-13-bringing-your-full-self-to-the-job-with-quinell-dixon.js?container_id=buzzsprout-player-4750970&player=small",
       "description": "Quinell is a father, choreographer, and entrepreneur. Back when he was a teenager he dabbled in business by creating a clothing line. It brought him so much pride and satisfaction to see others sporting the things he created. He is now a full time entrepreneur and has a channel on YouTube all about creating a successful hustle, even during an economic downturn, global pandemic, and all around crazy time to be launching a business. If you need the strength and motivation to start your business now, Quinell is your man. He’s beyond positive and extremely hard working.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000486546587",
       "spotify" : "https://open.spotify.com/episode/1rDqJYxCJ97Fkp7fme5Cjb?si=6ot7YPJxQZ2HQ4sEO-_4WA",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00NzUwOTcw",
       "casts" : "https://overcast.fm/+be5e0ffDA"
},
{
       "id": "ep14",
       "name": "Chad Flemming",
       "company": "Optometry CEO",
       "image": "/images/EntrepreneurImages/Ep-14-Don-t-Be-Afraid-to-Learn-Small-with-Chad-Flemming.jpg",
       "title": "Episode 14 - Don’t Be Afraid to Learn Small with Chad Flemming",
       "read": "https://www.getweave.com/ep-14-dont-be-afraid-to-learn-small-with-chad-flemming/",
       "watch" : "https://www.youtube.com/embed/h9PeJxiPbyc?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/4951979-episode-14-don-t-be-afraid-to-learn-small-with-chad-flemming.js?container_id=buzzsprout-player-4951979&player=small",
       "description": "Before he was anything, he was an entrepreneur. It’s been a defining characteristic. So, he didn’t see going into Optometry school, graduating, and opening a practice as anything other than an entrepreneurial move. In addition to everything he does as an optometrist, he’s truly stepped into the title of Entrepreneur with so many things he chooses to do outside of his business. If you need any self-imposed limits broken, come listen to this conversation with Chad.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000487900438",
       "spotify" : "https://open.spotify.com/episode/4aK9A5hZSQuRsi14BJsyzt?si=yablmhNYTO2fTampgAmuGQ",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC00OTUxOTc5",
       "casts" : "https://overcast.fm/+be5doHDcM"
   },
{
       "id": "ep15",
       "name": "Devin Pearce",
       "company": "DP Coaching",
       "image": "/images/EntrepreneurImages/Ep-15-Hiring-a-Business-Coach-with-Devin-Pearce.jpg",
       "title": "Episode 15 - Hiring a Business Coach with Devin Pearce",
       "read": "https://www.getweave.com/ep-15-finding-a-business-coach-with-devin-pearce/",
       "watch" : "https://www.youtube.com/embed/2meWrlUQkJM?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5053070-episode-15-finding-a-business-coach-with-devin-pearce.js?container_id=buzzsprout-player-5053070&player=small",
       "description": " So many of our guests have talked about times when they needed help with their business and reached out to get professional help from a coach. We thought it only appropriate to respond with an episode all about coaching-- why get coaching, finding the right coach, and getting the most out of coaching. Devin is a ICF certified coach and has worked with business owners around the world. We’ll dive into what makes coaching so great, why Devin believes everyone should have a coach, and the information you need to help you make the right investment in the coach best suited for you.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000488601372",
       "spotify" : "https://open.spotify.com/episode/3gtTSeeSGvkUvfeT54rNR7?si=_HnBIE9lTga5z1cZ28aXow",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01MDUzMDcw",
       "casts" : "https://overcast.fm/+be5egKtF8"
   },
{
       "id": "ep16",
       "name": "Jon Grover",
       "company": "Halosight",
       "image": "/images/EntrepreneurImages/Ep-16-Building-A-Better-Product-with-Jon-Grover.jpg",
       "title": "Episode 16 - Building a Better Product with Jon Grover",
       "read": "https://www.getweave.com/ep-16-building-a-better-product-with-jon-grover/",
       "watch" : "https://www.youtube.com/embed/6P0aClL3lcU?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5129722-episode-16-building-a-better-product-with-jon-grover.js?container_id=buzzsprout-player-5129722&player=small",
       "description": "You may or may not be considering starting a business or software company, but you’ll want to at the end of this episode. 2020 has inserted new problems and frustrations into the market, all of which need a solution. Hear Jon talk about his process to approaching product creation, how and when to ideate, and all of the inputs you need to make those two things run smoothly.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000489232942",
       "spotify" : "https://open.spotify.com/episode/7Jqhs7NFc86msfn0fx94zI?si=MG4DlVr3SgCKghCoHo6b6Q",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01MTI5NzIy",
       "casts" : "https://overcast.fm/+be5fnEmuA",
   },
{
       "id": "ep17",
       "name": "Ike Bennion",
       "company": "Cornerstone OnDemand",
       "image": "/images/EntrepreneurImages/Ep-17-Ensuring-Business-Relevance-and-Viability-with-Ike-Bennion.jpg",
       "title": "Episode 17 - Ensuring Business Relevance and Viability with Ike Bennion",
       "read": "https://www.getweave.com/ep-17-ensuring-business-relevance-and-viability-with-ike-bennion/",
       "watch" : "https://www.youtube.com/embed/P2ytvLmxN-8?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5247394-episode-17-ensuring-business-relevance-and-viability-with-ike-bennion.js?container_id=buzzsprout-player-5247394&player=small",
       "description": " Ike has a phenomenal understanding of how to help you check your business idea’s viability. As a self proclaimed data nerd, he’ll walk you through how to collect data on a budget so you can make the informed decisions that are crucial when starting a business. If you’ve had a business idea, use his steps to help validate your idea.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000489890924",
       "spotify" : "https://open.spotify.com/episode/2BFfBkxw06i3Vq0NL2TAB0?si=X_C_yJQ5TlycJ5Y4Q1hMLQ",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01MjQ3Mzk0",
       "casts" : "https://overcast.fm/+be5dHWwRQ"
   },
{
       "id": "ep18",
       "name": "Milind Kopikare",
       "company": "",
       "image": "/images/EntrepreneurImages/Ep-18-Uncovering-Your-Customer-s-Unmet-Need-with-Milind-Kopikare.jpg",
       "title": "Episode 18 - Uncovering Your Customer’s Unmet Need with Milind Kopikare",
       "read": "https://www.getweave.com/ep-18-uncovering-your-customers-unmet-need/",
       "watch" : "https://www.youtube.com/embed/DgGNWJRwTZQ?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5365915-episode-18-uncovering-your-customer-s-unmet-need-with-milind-kopikare.js?container_id=buzzsprout-player-5365915&player=small",
       "description": "Ideating is fun. You've probably thought, \"I could solve that problem better\" or \"I could produce a better X.\" In today's episode you'll find it's not as easy as that. Focusing on the obvious problem may lead to a failed business venture. Listen to entrepreneur, product expert, and professor, Milind Kopikare, talk about how you can uncover your customer's unmet need and develop a solution to a social or emotional problem (those not so obvious issues).",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000490758701",
       "spotify" : "https://open.spotify.com/episode/1K1DAzAn4dvIsb9co4T2yR?si=-giAQZrgTlqz5i2bXpvaqA",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01MzY1OTE1",
       "casts" : "https://overcast.fm/+be5ej3Agw"
   },
{
       "id": "ep19",
       "name": "Gretchen Figge",
       "company": "Elf Works Inc.",
       "image": "/images/EntrepreneurImages/Ep-19-Knowing-Your-Numbers-Inside-&-Out-with-Gretchen-Figge.jpg",
       "title": "Episode 19 - Knowing Your Numbers Inside & Out with Gretchen Figge",
       "read": "https://www.getweave.com/ep-19-knowing-your-numbers-inside-out-with-gretchen-figge/",
       "watch" : "https://www.youtube.com/embed/xU7wm6KtUpA?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5458723-episode-19-knowing-your-numbers-inside-out-with-gretchen-figge.js?container_id=buzzsprout-player-5458723&player=small",
       "description": " Many of us become entrepreneurs because we’re really good at something, no longer want a boss, and want to turn that skill or passion into a business. To make any business successful, you need that skill, the entrepreneur dream, and the management mind. And a big part of the management mind is finances-- knowing your numbers. For 20 years, Gretchen has consulted small businesses on knowing their number. Before we get to the good stuff, I’ve got a little one year old who has recently learned how to walk and may have had one too many falls this morning. You may hear her in the background when I wasn’t quick enough to mute myself.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000491425374",
       "spotify" : "https://open.spotify.com/episode/3qJUKlnp50QthvjSpP0aBd?si=FmLN9qqSQ4GNM1ahFjNwrw",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01NDU4NzIz",
       "casts" : "https://overcast.fm/+be5f8zcgI"
   },
{
       "id": "ep20",
       "name": "Debbie Boone",
       "company": "2 Manage Vets Consulting",
       "image": "/images/EntrepreneurImages/Ep-20-Managing-Brand-and-Online-Presence-with-Debbie-Boone.jpg",
       "title": "Episode 20 - Managing Brand and Online Presence with Debbie Boone",
       "read": "https://www.getweave.com/ep-20-managing-brand-online-presence/",
       "watch" : "https://www.youtube.com/embed/jkfSvSY435c?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5542114-episode-20-managing-brand-online-presence-with-debbie-boone.js?container_id=buzzsprout-player-5542114&player=small",
       "description": "Did you know that most veterinarians made that job decision when they were kids? And they followed through! Not very typical, seeing as those of us who wanted to be astronauts may not have actually pursued that path. But the thing about any career path is that there are revenue creating behaviors that you and no one else can do. You’ve got to attend to those puppies, teeth, knees, cuticles, or hair. But everything else? It can be delegated. Listen to Debbie talk about how important it is for you to do those things, focus on your brand, and make sure that those you delegate to are true to your brand.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000492238754",
       "spotify" : "https://open.spotify.com/episode/65yroyyb6HqHMXvhouQaeX?si=Igs4plwcTQG89B1wXTiAOA",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01NTQyMTE0",
       "casts" : "https://overcast.fm/+be5dIAuek"
   },
{
       "id": "ep21",
       "name": "Marcus Wing",
       "company": "4FX Productions",
       "image": "/images/EntrepreneurImages/Ep-21-Remaining-Optimistic-Despite-Your-Scenario-with-Marcus-Wing.jpg",
       "title": "Episode 21 -Remaining Optimistic Despite Your Scenario with Marcus Wing ",
       "read": "https://www.getweave.com/ep-21-remaining-optimistic-despite-your-scenario-with-marcus-wing/",
       "watch" : "https://www.youtube.com/embed/313aAOdGloI?rel=0",
       "listen" : "https://www.buzzsprout.com/1090415/5790313-episode-21-remaining-optimistic-despite-your-scenario-with-marcus-wing.js?container_id=buzzsprout-player-5790313&player=small",
       "description": "No one has been more affected by COVID than event producers. In 2 days, Marcus lost 98% of his business. Did he have a minute where he almost ripped his hair out? Yes. But he probably didn’t let it last much longer than that. Though this may be a novel and big shift in the market, it’s not something you’d go without experiencing during your life as an entrepreneur. Something someday will turn your world upside down. What will you do about it? Listen to how he responded to this dramatic and sudden loss, what he’s done to creatively pivot, and how he’s never let his spirits get down. Let’s get to the good stuff.",
       "apple" : "https://podcasts.apple.com/us/podcast/weave-connected/id1514476734?i=1000494009496",
       "spotify" : "https://open.spotify.com/episode/4W24s0TpGV7Ra5oU4WoOnt?si=qiI5x8OtRrWo3niQ56gWcA",
       "google" : "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDkwNDE1LnJzcw==/episode/QnV6enNwcm91dC01NzkwMzEz",
       "casts" : "https://overcast.fm/+be5dd2KaU"
   }
];